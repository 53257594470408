import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as Icons from 'react-feather'

const weekSummary = [
  {
    title:"Week 2: Staff & Pupils",
    info: [
      <p className="mb-0">During this week you’ll roll out training to staff and launch the app with your pupils.</p>,
      <p>We’ll provide training tools and in-class materials to help both staff and pupils to get logged and reading.</p>
    ]
  },
  {
    title: "Week 3: Parents",
    info: [
      <p className="mb-0">You'll officially launch with parents. </p>,
      <p>As before, we'll provide material to help you inform and get your parents using the app to log reading.</p>
    ]
  },
  {
    title: "Week 4: Advanced Settings & The Future",
    info: [
      <p className="mb-0">Now you are up and running with the app, both in school and at home, we’ll talk you through the advanced features including how you can start to use the data. </p>,
      <p>We will also share with you how to access the Summer Readathon which is a great way to encourage your students to keep on reading over summer.</p>
    ]
  }
]

const GrandTourPage = () => (
  <Layout>
    <Seo title="Guides" />

    <div className="gtHeader">
      <div className="container gtHeader_layout">
        <StaticImage src="../images/grand-tour/grand-tour-2023.png" alt="Boomreader Grand Tour" width={400} className="gtHeader_logo" />
      </div>
    </div>

    <div className="gtBody container">
      <div className="txt-center">
        <h1 className="h2">Welcome to the Grand Tour</h1>
        <p className="h-small mb-">Over the next 4 weeks we’ll guide you through rolling out BoomReader across your school.</p>
        <p className="txt-large">Each week you'll be able to download the step-by-step guides and support files to help you successfully roll out digital reading diaries.</p>
      </div>

      <section className="gtWeek mb-1">
        <h1 className="h-small">Week 1: Set Up & Getting Started</h1>
        <p className="mb-0">Week 1 is all about setting up your school account and preparing to give staff and pupils training in week 2.</p>
        <p>Before you sign-up to start your trial - please download and review the following files:</p>
        <ol className="gtWeek_downloads">
          <li><a href="https://boomhub.freshdesk.com/helpdesk/attachments/151023651233" className="gtWeek_link">Getting Started (PPTX)</a></li>
          <li><a href="https://boomhub.freshdesk.com/helpdesk/attachments/151023644635" className="gtWeek_link">Reading Bands (PPTX)</a></li>
          <li><a href="https://boomhub.freshdesk.com/helpdesk/attachments/151023644652" className="gtWeek_link">Basic Settings (PPTX)</a></li>
          <li><a href="https://boomhub.freshdesk.com/helpdesk/attachments/151023644669" className="gtWeek_link">Managing Classes & Students (PPTX)</a></li>
        </ol>
      </section>

      <section className="gt-grid3 mb-1">
        {weekSummary.map(s => (
          <div className="gtWeekLocked">
            <h2 className="h-small">{s.title}</h2>
            {s.info}
          </div>
        ))}
      </section>

      <section className="gtWeek">
        <h2 className="h-small">Need Help?</h2>
        <a href="mailto:support@boomhub.app?subject=Grand%20Tour" className="gtWeek_link">Email Us: support@boomhub.app</a>
      </section>
      
      {/* <div className="gtLinks">
        <div className="gtLinks_week">
          <a href="https://x8l1l.mjt.lu/nl3/CrBo5kDb32MbFBwoCNbjiA?hl=en" className="gtLinks_link" target="_blank" rel="noreferrer">
            <h2 className="gtLinks_title">Week 1 <br/>Getting Started</h2>
            <p className="gtLinks_desc">Supporting staff to get up and running with BoomReader as quickly and easily as possible</p>
            <Icons.CheckCircle className="gtLinks_icn" />
          </a>
          <a href="https://vimeo.com/717273750/e2ad4cf759" className="gtLinks_video" target="_blank" rel="noreferrer"><Icons.Video alt="Video" /> Watch the Recorded Webinar</a>
        </div>
        <div className="gtLinks_week">
          <a href="https://x8l1l.mjt.lu/nl3/ohpjvneSurbMY2jAiHEUFw?hl=en" className="gtLinks_link" target="_blank" rel="noreferrer">
            <h2 className="gtLinks_title">Week 2 <br/>Parental Engagement</h2>
            <p className="gtLinks_desc"><strong>Before you send parent letters home...</strong> <br/>Join the webinar with headteacher Claire Jones to discuss how to maximise parental engagement and prepare for the launch of our all-new parent app!</p>
            <Icons.CheckCircle className="gtLinks_icn" />
          </a>
          <a href="https://vimeo.com/792279110/dfd5b611c3" className="gtLinks_video" target="_blank" rel="noreferrer"><Icons.Video alt="Video" /> Watch the Recorded Webinar</a>
        </div>
        <div className="gtLinks_week">
          <a href="https://x8l1l.mjt.lu/nl3/-QVa7bhMaLylz2psOl9NCA?hl=en" className="gtLinks_link" target="_blank" rel="noreferrer">
            <h2 className="gtLinks_title">Week 3 <br/>Pupil Engagement</h2>
            <p className="gtLinks_desc">The next step is supporting your pupils to access the app.</p>
            <Icons.CheckCircle className="gtLinks_icn" />
          </a>
          <a href="https://vimeo.com/794583261/df131d8dd0" className="gtLinks_video" target="_blank" rel="noreferrer"><Icons.Video alt="Video" /> Watch the Recorded Webinar</a>
        </div>
        <div className="gtLinks_week">
          <a href="https://x8l1l.mjt.lu/nl3/G0Je0mGtJYthCFZRVm-99Q?hl=en" className="gtLinks_link" target="_blank" rel="noreferrer">
            <h2 className="gtLinks_title">Week 4 <br/>Next Steps and BoomWriter</h2>
            <p className="gtLinks_desc">Sharing the next steps BoomReader and introducing the writing platform, BoomWriter that compliments the BoomReader platform.</p>
            <Icons.CheckCircle className="gtLinks_icn" />
          </a>
          <a href="https://vimeo.com/798745537/30dad557ca" className="gtLinks_video"><Icons.Video alt="Video" /> Watch the Recorded Webinar</a>
        </div>
      </div> */}
    </div>
  </Layout>
)

export default GrandTourPage